import has from "has";

export default function (module) {
    module.config([
        "$compileProvider",
        "$mdGestureProvider",
        function ($compileProvider, $mdGestureProvider) {
            // eslint-disable-line angular/di
            /**
             * https://docs.angularjs.org/guide/production
             *
             * improve performance by only enabling debug information in development mode
             *
             * use angular.reloadWithDebugInfo(); to enable it on the fly
             */
            $compileProvider.debugInfoEnabled(has("development"));
            $compileProvider.aHrefSanitizationWhitelist(
                /^\s*(local|http|https|sbdaily):|data:image\//
            );

            // Tell the AngularJS Material Gesture module to skip (or ignore) click hijacking on mobile devices.
            $mdGestureProvider.skipClickHijack();
        },
    ]);
}
