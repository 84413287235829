import angular from "angular";
import _ from "lodash";

export default function DeliverableWorkflowCtrl(
    $scope,
    $sbCurrentProject,
    $sbPermission,
    $sbMembership,
    $sbTeam,
    sbDeliverableWorkflowService,
    Analytics,
    $sbTracking
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.templateActivities = undefined;

    vm.hasPermissionToAssignProcess = undefined;

    vm.onFilterByTeam = onFilterByTeam;
    vm.removeTeamFilter = removeTeamFilter;
    vm.hasTemplateRevisionNumber = hasTemplateRevisionNumber;
    vm.getTemplateRevisionNumber = getTemplateRevisionNumber;
    vm.hasActivities = hasActivities;
    vm.onDeleteUserDefinedDateOf = onDeleteUserDefinedDateOf;
    vm.hasDeletePermissionForCustomDates = hasDeletePermissionForCustomDates;

    vm.isLoadingShown = function () {
        return vm.isProcessLoading;
    };
    vm.isWorkflowTreeShown = function () {
        return !vm.isProcessLoading && hasVisibleActivities();
    };
    vm.isEmptyFilterResultShown = function () {
        return (
            !vm.isProcessLoading &&
            !hasVisibleActivities() &&
            vm.hasActivities()
        );
    };
    vm.isAssignTemplateShown = function () {
        return !vm.isProcessLoading && !hasTemplateAssignment();
    };
    vm.isEmptyTemplateAssigned = function () {
        return (
            !vm.isProcessLoading &&
            hasTemplateAssignment() &&
            !vm.hasActivities()
        );
    };

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    var accordionWatcher = $scope.$on("vAccordion:onExpand", onActivityExpand);

    vm.$onDestroy = () => accordionWatcher();
    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function $onChanges(changes) {
        if (changes.deliverable) {
            const currentDeliverable = _.get(
                changes,
                "deliverable.currentValue.id"
            );
            const previousDeliverable = _.get(
                changes,
                "deliverable.previousValue.id"
            );
            if (
                currentDeliverable &&
                currentDeliverable !== previousDeliverable
            ) {
                if ($sbMembership.currentTeam().isProjectTeam) {
                    Analytics.trackEvent(
                        "Details Overlay",
                        "click",
                        "default show all activities"
                    );
                } else {
                    Analytics.trackEvent(
                        "Details Overlay",
                        "click",
                        "default specific team activities"
                    );
                }
            }
        }

        if (changes.process || changes.teams || changes.selectedTeam) {
            _prepareWorkflowAndSetVm(vm.process, vm.selectedTeam, vm.teams);
        }
    }

    function $onInit() {
        var project = $sbCurrentProject.get();

        vm.hasPermissionToAssignProcess = $sbPermission.hasPermission(
            project.privileges,
            "ASSIGN_PROCESS"
        );
    }

    function onDeleteUserDefinedDateOf(node) {
        return sbDeliverableWorkflowService.onDeleteUserDefinedDateOf(
            node,
            vm.deliverable
        );
    }

    function hasDeletePermissionForCustomDates() {
        var project = $sbCurrentProject.get();
        return $sbPermission.hasPlanPermissions(project.privileges);
    }

    /**
     * Clone and filter the given workflow. Set the view model with the results.
     *
     * @param {Array<SbBaseComponent>} workflow
     * @param {SbTeam} forTeam
     * @param {Array<SbTeam>} teams
     * @private
     */
    function _prepareWorkflowAndSetVm(workflow, forTeam, teams) {
        var filterByTeam =
            sbDeliverableWorkflowService.generateIsInaccessibleByTeamMethod(
                forTeam
            );

        var componentTree =
            sbDeliverableWorkflowService.removeActivitiesByCondition(
                angular.copy(workflow),
                filterByTeam
            );

        componentTree = sbDeliverableWorkflowService.assignTeamToWorkflow(
            componentTree,
            teams
        );

        vm.templateActivities = componentTree;
    }

    function hasActivities() {
        return _.isArray(vm.process) && vm.process.length > 0;
    }

    function hasTemplateAssignment() {
        return _.isObject(vm.template) && _.isString(vm.template.ID);
    }

    function hasVisibleActivities() {
        return (
            _.isArray(vm.templateActivities) && vm.templateActivities.length > 0
        );
    }

    function hasTemplateRevisionNumber() {
        return vm.deliverable.templateRevision > 0;
    }

    function getTemplateRevisionNumber() {
        return vm.deliverable.templateRevision;
    }

    function onFilterByTeam(team) {
        if (team.isProjectTeam) {
            Analytics.trackEvent(
                "Details Overlay",
                "click",
                "changed to show all activities"
            );
        } else {
            Analytics.trackEvent(
                "Details Overlay",
                "click",
                "changed to specific team activities"
            );
        }

        vm.onSelectedTeamChange({ team });
    }

    function removeTeamFilter() {
        onFilterByTeam($sbTeam.findProjectTeam(vm.teams));
    }

    function onActivityExpand() {
        $sbTracking.detailsOverlay.activity.expand();
    }
}
