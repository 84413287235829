import _ from "lodash";
import moment from "moment";
import SbTeam from "../../../../domain/sb_team.class";

/*eslint angular/no-inline-template: 0*/

export default class DeliverablesOverlayDataService {
    constructor(
        SbDeliverable,
        $sbDeliverablesApi,
        $sbDeliverableNotesApi,
        $sbDeliverableWorkflow,
        $sbDeliverableChecklists,
        $sbTemplate,
        $sbCalendarRepository,
        $sbTeam,
        $q,
        $log,
        $sbMembership,
        $filter,
        $rootScope,
        EVENTS
    ) {
        "ngInject";

        // dependencies
        this.SbDeliverable = SbDeliverable;
        this.$sbDeliverablesApi = $sbDeliverablesApi;
        this.$sbDeliverableNotesApi = $sbDeliverableNotesApi;
        this.$sbDeliverableWorkflow = $sbDeliverableWorkflow;
        this.$sbDeliverableChecklists = $sbDeliverableChecklists;
        this.$sbTemplate = $sbTemplate;
        this.$sbCalendarRepository = $sbCalendarRepository;
        this.$sbTeam = $sbTeam;
        this.$log = $log;
        this.$q = $q;
        this.$sbMembership = $sbMembership;
        this.$filter = $filter;
        this.$scope = $rootScope;
        this.EVENTS = EVENTS;

        // model
        this.current = {
            // cached
            project: undefined,
            calendar: undefined,
            teams: undefined,

            // always updated
            deliverable: new this.SbDeliverable(),
            template: undefined,
            process: undefined,
            notes: undefined,
            selectedTeam: undefined,
            checklists: undefined,
        };

        this.loading = {
            deliverable: true,
            template: true,
            process: true,
            notes: true,
            checklists: true,
        };
    }

    /**
     * Set the project used for calculations and displaying information
     *
     * @param {SbProject} project
     */
    setCurrentProject(project) {
        this.$log.info("set current project", {
            current: this.current,
            project,
        });

        this.current.project = project;

        this.$sbTeam.getTeams(project.id).then((teams) => {
            this.current.teams = teams;
            this.current.teamsAsFilterOptions =
                this._createListOfTeamsForViewFiltering(teams);

            const myTeam = this.$sbMembership.currentTeam();
            if (myTeam.isProjectTeam) {
                // the first option is by design the "everything" option
                //   which we want to use full access team members
                this.current.selectedTeam =
                    this.current.teamsAsFilterOptions[0];
            } else {
                // otherwise we preselect the users team
                this.current.selectedTeam = _.find(
                    this.current.teamsAsFilterOptions,
                    ["id", myTeam.id]
                );
            }
        });

        this.$sbCalendarRepository
            .get(project.id)
            .then((calendar) => (this.current.calendar = calendar));
    }

    isCurrent(id) {
        const currentDeliverableId = _.get(this.current, "deliverable.id");
        return id === currentDeliverableId;
    }

    unset() {
        this.current.deliverable = new this.SbDeliverable();
        this.current.template = undefined;
        this.current.process = undefined;
        this.current.notes = undefined;
        this.current.checklists = undefined;

        this.loading.deliverable = true;
        this.loading.template = true;
        this.loading.process = true;
        this.loading.notes = true;
        this.loading.checklists = true;
    }

    load(deliverableId) {
        this.unset();

        if (!_.isString(deliverableId)) {
            return;
        }

        return this.$sbDeliverablesApi
            .get(this.current.project.id, deliverableId)
            .then((result) => this.toDeliverableDomain(result))
            .then((deliverable) => {
                this.loadTemplate(deliverable);
                return this.loadProcess(deliverable);
            });
    }

    loadProcess(deliverableEntity) {
        return Promise.all([
            this.$sbDeliverableWorkflow.loadWorkflow(deliverableEntity),
            this.loadNotes(deliverableEntity),
        ]).then(([{ content, deliverable }, notes]) => {
            // make and store the deliverable
            deliverableEntity.setChildren(content);
            this.current.deliverable = this.mergeDeliverableEntity(
                deliverableEntity,
                deliverable
            );
            this.loading.deliverable = false;

            this.current.notes = this.mergeNotesAndProcess(
                notes,
                deliverableEntity
            );

            this.current.process = content;

            this.loading.process = false;
            this.loading.notes = false;

            return this.current.deliverable;
        });
    }

    pickActivityChecklistFor(deliverableId, activityId, leadingChecklistId) {
        return this.loadChecklists(deliverableId, activityId).then(
            (checklists) => {
                const checklist = checklists.find(
                    (checklist) =>
                        checklist.executionChecklist?.id === leadingChecklistId
                );
                if (checklist) {
                    return checklist;
                }

                const template = checklists.find(
                    (checklist) =>
                        checklist.templateChecklist?.id ===
                            leadingChecklistId &&
                        checklist.activity.id === activityId
                );
                if (template) {
                    return template;
                }

                return this.$q.reject("Checklist not found");
            }
        );
    }

    /**
     * Updates from server the current deliverable if available.
     *
     * @note - Notes, Process or Template are not updated from this call.
     */
    reloadCurrentDeliverable() {
        const deliverableId = _.get(this.current, "deliverable.id");
        const projectId = _.get(this.current, "project.id");

        if (!deliverableId || !projectId) {
            return Promise.resolve();
        }

        const children = this.current.deliverable.getChildren();

        return this.$sbDeliverablesApi
            .get(projectId, deliverableId)
            .then((apiData) => this.toDeliverableDomain(apiData))
            .then((deliverableEntity) => {
                return this.$sbDeliverableWorkflow
                    .loadWorkflow(deliverableEntity)
                    .then(({ deliverable }) => {
                        // make and store the deliverable
                        deliverableEntity.setChildren(children);

                        this.current.deliverable = this.mergeDeliverableEntity(
                            deliverableEntity,
                            deliverable
                        );

                        return this.current.deliverable;
                    });
            });
    }

    toDeliverableDomain(apiData) {
        const deliverable = new this.SbDeliverable(
            apiData.id,
            apiData.name,
            apiData.code
        );
        deliverable.projectId = this.current.project.id;
        deliverable.piTemplateId = apiData.template_id;
        deliverable.structureId = apiData.structure_id;
        deliverable.description = apiData.description;
        deliverable.startDate = moment(
            apiData.start_date,
            moment.ISO_8601,
            true
        );
        deliverable.endDate = moment(apiData.end_date, moment.ISO_8601, true);
        deliverable.baselineSchedule = {};
        deliverable._noteStatistic = {};
        deliverable.typeName = apiData.type_name;
        return deliverable;
    }

    mergeDeliverableEntity(entity, apiData) {
        entity.areaManagerUserName = apiData.AREA_MANAGER_USER_NAME;
        entity.assignedTemplateRevision = apiData.TEMPLATE_REVISION;
        entity._noteStatistic = this._parseNoteStatistics(apiData);
        entity.baselineSchedule = {
            earliestStart: moment(
                apiData.EARLIEST_START,
                moment.ISO_8601,
                true
            ),
            earliestEnd: moment(apiData.EARLIEST_END, moment.ISO_8601, true),
            latestStart: moment(apiData.LATEST_START, moment.ISO_8601, true),
            latestEnd: moment(apiData.LATEST_END, moment.ISO_8601, true),
            totalFloat: apiData.TOTAL_FLOAT,
        };
        entity.issueChangeTime = moment(
            apiData.ISSUES_LAST_CHANGE_TIME,
            moment.ISO_8601,
            true
        );
        entity.templateRevision = apiData.TEMPLATE_REVISION;
        return entity;
    }

    loadTemplate(deliverable) {
        return this.$sbTemplate
            .get(deliverable.piTemplateId)
            .then((template) => (this.current.template = template))
            .then(() => (this.loading.template = false))
            .catch(this.$log.error);
    }

    loadNotes(deliverable) {
        return this.$sbDeliverableNotesApi
            .getCollection(deliverable.id)
            .then(({ notes }) => this.parseApiNotes(notes))
            .then((notes) =>
                notes.filter((note) => note.state.name !== "removed")
            );
    }

    loadChecklists(deliverableId, activityId) {
        if (this.current.checklists !== undefined) {
            return Promise.resolve(this.current.checklists);
        }
        return this.$sbDeliverableChecklists
            .fetchChecklistsByDeliverableId(deliverableId)
            .then((checklists) => (this.current.checklists = checklists))
            .then(() => {
                if (activityId) {
                    return this.current.checklists.filter(
                        (checklist) => checklist.activity.id === activityId
                    );
                } else {
                    return this.current.checklists;
                }
            })
            .finally(() => (this.loading.checklists = false));
    }

    parseApiNotes(notes) {
        return notes.map((note) => {
            this._setResponsibleTeam(note);
            this._setConfirmationTeam(note);

            return note;
        });
    }

    _setResponsibleTeam(note) {
        const assignedTeamId = _.get(note, "assigned_team.id");
        if (assignedTeamId) {
            note.assigned_team = _.find(this.current.teams, [
                "id",
                assignedTeamId,
            ]);
        } else {
            note.assigned_team = this.$sbTeam.createUnrestrictedTeam();
        }
    }

    _setConfirmationTeam(note) {
        const confirmationTeamId = _.get(note, "confirmation_team.id");
        note.confirmation_team = _.find(this.current.teams, [
            "id",
            confirmationTeamId,
        ]);
    }

    mergeNotesAndProcess(notes, deliverable) {
        return notes.map((note) => {
            note.deliverable = deliverable;

            const activity = deliverable.find(note.activity_id);
            if (!activity) {
                return note;
            }

            note.activity = activity;
            this.decorateNoteStatsIn(activity, note.type, note.state.name);

            return note;
        });
    }

    decorateNoteStatsIn(activity, type, state) {
        switch (type) {
            case "info":
                this.forEachParentOf(
                    activity,
                    (element) => element._noteStatistic.info++
                );
                return;
            case "obstruction":
                if (this.isNoteClosed(state)) {
                    this.forEachParentOf(
                        activity,
                        (element) => element._noteStatistic.closedObstructions++
                    );
                } else {
                    this.forEachParentOf(
                        activity,
                        (element) => element._noteStatistic.openObstructions++
                    );
                }
                return;
            case "quality_issue":
                if (this.isNoteClosed(state)) {
                    this.forEachParentOf(
                        activity,
                        (element) => element._noteStatistic.closedClaims++
                    );
                } else {
                    this.forEachParentOf(
                        activity,
                        (element) => element._noteStatistic.openClaims++
                    );
                }
                return;
        }
    }

    isNoteClosed(state) {
        switch (state) {
            case "open":
            case "rejected":
            case "waiting_for_confirmation":
                return false;
            case "closed":
            case "done":
            case "confirmed":
                return true;
        }
    }

    forEachParentOf(element, fn) {
        fn(element);
        if (element.hasParent()) {
            this.forEachParentOf(element.getParent(), fn);
        }
    }

    handleNoteAdded(newNoteFromServer) {
        this.$scope.$emit(
            this.EVENTS.COMPONENT_DETAIL__NOTE_ADDED,
            newNoteFromServer.deliverable_id
        );

        this.reloadCurrentDeliverable().then(() => {
            const notes = this.parseApiNotes([newNoteFromServer]);
            const mergedNotesAndProcess = this.mergeNotesAndProcess(
                notes,
                this.current.deliverable
            );

            this.current.notes = (this.current.notes || []).concat(
                mergedNotesAndProcess
            );
            this.loading.notes = false;
        });
    }

    handleNoteModified(modifiedNoteFromServer) {
        this.reloadCurrentDeliverable().then(() => {
            const oldNoteIndex = _.findIndex(
                this.current.notes,
                _.matchesProperty("id", modifiedNoteFromServer.id)
            );

            if (oldNoteIndex !== -1) {
                const parsedNotes = this.parseApiNotes([
                    modifiedNoteFromServer,
                ]);

                const mergedNote = this.mergeNotesAndProcess(
                    parsedNotes,
                    this.current.deliverable
                );

                this.current.notes[oldNoteIndex] = mergedNote[0];
            }
        });
    }

    _parseNoteStatistics(apiData) {
        return {
            closedObstructions: apiData.CLOSED_OBSTRUCTION_QUANTITY,
            openObstructions: apiData.OBSTRUCTION_QUANTITY,
            closedClaims: apiData.CLOSED_CLAIM_QUANTITY,
            openClaims: apiData.CLAIM_QUANTITY,
            info: apiData.INFO_QUANTITY,
        };
    }

    _createListOfTeamsForViewFiltering(teams) {
        const projectTeam = teams.find((team) => team.isProjectTeam);

        // we take the project team but remove the "isProjectTeam" flag
        // which makes it a normal team that can be used for filtering
        const projectTeamAsRegularTeam = new SbTeam(
            projectTeam.id,
            SbTeam.PROJECT_TEAM_I18N_KEY
        );
        projectTeamAsRegularTeam.color = projectTeam.color;

        // we are adding an artificial "show-all" case to the list of teams
        //
        const selectAllTeam = new SbTeam(-1, "all");
        // this is the way we are identifying "show all" in the team select.
        selectAllTeam.isProjectTeam = true;

        const otherTeams = teams.filter((team) => !team.isProjectTeam);

        // we keep the project team as the first element in the list because it represents "show all" in the UI
        // second will be the "normalized" project team
        // then the rest
        return [selectAllTeam, projectTeamAsRegularTeam, ...otherTeams];
    }
}
